import * as React from "react";
import { useEffect } from "react";
import { navigate } from "@reach/router";

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/");
  }, []);
  return null;
};

export default NotFoundPage;

export const Head = () => <title>Not found</title>;
